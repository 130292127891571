import React from "react";
import { array, func } from 'prop-types';

import DealItem from '../../sellers/DealItem';
import Cdn_path from "../../../services/cdn_path";

const DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right',
};

class RecentDealsSliderDesktop extends React.Component {
    constructor(props) {
        super(props);

        this.timerId = null;
        this.disableTransition = false; // when changing itemtype and scroll carousel to initial pos should disable animation.

        this.state = {
            soldItems: props.soldItems,
            hiddenItemIndex: 0,
        };

        this.moveCarouselTo = this.moveCarouselTo.bind(this);
        this._handleMouseHoldOnSlider = this._handleMouseHoldOnSlider.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.soldItems !== this.props.soldItems) {
            this.disableTransition = true;
            this.setState({ hiddenItemIndex: 0 });
            setTimeout(() => this.disableTransition = false, 100); // half time of animation time
        }
    }

    moveCarouselTo(direction) {
        const { hiddenItemIndex } = this.state;

        this.props.pushScrollGAEvent();

        if (direction === DIRECTIONS.RIGHT) {
            this.setState({ hiddenItemIndex: hiddenItemIndex + 1 })
        } else {
            this.setState({ hiddenItemIndex: this.state.hiddenItemIndex - 1 })
        }
    }

    _handleMouseHoldOnSlider(direction) { // type = MouseDown || MouseUp
        let ticks = 0;

        if (!this.timerId) {
            this.timerId = setInterval(() => {
                let { hiddenItemIndex }  = this.state;
                if (ticks >= 1) {
                    if (direction === DIRECTIONS.RIGHT && hiddenItemIndex <= 3) {
                        this.setState({ hiddenItemIndex: ++hiddenItemIndex });
                    } else if (direction === DIRECTIONS.LEFT && hiddenItemIndex >= 1) {
                        this.setState({ hiddenItemIndex: --hiddenItemIndex });
                    }
                }
                ticks++;
            }, 300)
        } else {
            clearInterval(this.timerId);
            this.timerId = null;
        }
    }

    render() {
        const { hiddenItemIndex } = this.state;
        /*
       * In state soldItems added first dummy element in order to render animation for first element
       * This method calculation width from left. 25 it's cause we have 4 items per one frame in slider.
       *  hiddenItemIndex === 0 cause first element in the soldItems is dummy.
       * */
        const calcTransformLeft = () => hiddenItemIndex === 0 ? 25 : hiddenItemIndex * ( 100 / 4 ) + 25;

        const renderArrow = (direction) => {
            const { hiddenItemIndex } = this.state;
            const isInActive = direction == DIRECTIONS.LEFT && hiddenItemIndex === 0 || direction === DIRECTIONS.RIGHT && hiddenItemIndex === 4;

            return (
                <div
                    onClick={isInActive ? null : () => this.moveCarouselTo(direction) }
                    onMouseDown={() => this._handleMouseHoldOnSlider(direction)}
                    onMouseUp={() => this._handleMouseHoldOnSlider()}
                    className="recent-deals-slider-arrow"
                >
                    <img
                        src={ isInActive ? Cdn_path('frontend/slider/arrow_inactive.svg') : Cdn_path('frontend/slider/arrow_active.svg') }
                        style={{
                            transform: direction === DIRECTIONS.LEFT ? 'rotate(180deg)' : 'rotate(0deg)'
                        }}
                    />
                </div>
            )
        };

        return (
            <div style={{ display: 'flex' }}>
                { renderArrow(DIRECTIONS.LEFT) }
                <div className="deals-slider">
                    <div
                        className="deals-slider-wrapper"
                        style={{
                            'transform': `translateX(-${calcTransformLeft()}%)`,
                            transition: this.disableTransition ? 'none' : '',
                        }}
                    >
                        {
                            this.props.soldItems.map((deal, i) => (
                                <div
                                    onClick={this.props.pushClickGAEvent}
                                    style={{
                                        transition: this.disableTransition ? 'none' : '',
                                    }}
                                    className={`deals-slider-item ${hiddenItemIndex === i || hiddenItemIndex + 5 === i  ? 'animate' : ''}`}
                                    key={i}
                                >
                                    <DealItem
                                        deal={deal}
                                        withGridLayout={false}
                                        showTimeAgo={this.props.showTimeAgo}
                                    />
                                </div>
                            ))
                        }
                    </div>

                </div>
                { renderArrow(DIRECTIONS.RIGHT) }
            </div>
        )
    }
}
RecentDealsSliderDesktop.propTypes = {
    soldItems: array,
    pushClickGAEvent: func,
    pushScrollGAEvent: func,
};

export default RecentDealsSliderDesktop;
