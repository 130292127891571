import React, { useState, useEffect } from 'react';
import {LargeLinkButton, LargeSecondaryButton} from '@worthy-npm/clarity';
import axios from "axios";
import { goTo, isMobile } from '../filters/filter_constants_and_helpers.jsx.erb';

let isProcessingRequest = false
const ExplorePotentialValue = (props) => {
    const [decision, setDecision] = useState('dontShow')
    const [showButton, setShowButton] = useState(false)

    const TIMEOUT_EXPLORE_VALUE_CTA = 3000
    let getSessionDataTake = 0
    let sendToFsTake = 0
    let timeoutP13NRequest = 500
    const currentMoment = new Date()
    let hpPresentedAt = currentMoment.getTime()
    const LS_CACHE_KEY_PREFIX = 'showExplorePotentialValue::'
    const PUB_SUB_EVENT = 'EXPLORE_POTENTIAL_VALUE::set'

    useEffect(() => {
        const params = new URLSearchParams( window.location.search)
        const forceDecision = params.get('forceDecision')
        retrySend(forceDecision)
    }, [])

    const updateDecisionDontShow = (gaClientId, forceDecision) => {
        const utmCookie = window.getCookie('__utmsession')
        const sessionData = JSON.parse(utmCookie)
        const payload = {
            gaclient: gaClientId,
            visitorType: 'visitor',
            session: sessionData,
            debugData: getDebugData()
        }

        if (forceDecision) {
            payload.session.forceDecision = forceDecision.split('_')[1]
        }
        sendToFS("explorePotentialValueLateStart", { time: currentMoment, gaClientId: gaClientId, forceDecision: forceDecision })

        isProcessingRequest = true
        axios.post(`${window.PERSONALIZER_URL}/explorePotentialValueLate`, payload)
            .then((res)=> {
                PubSub.publish(PUB_SUB_EVENT, { decision, url: getTargetUrl(forceDecision) })
                localStorage.setItem(LS_CACHE_KEY_PREFIX + gaClientId, res.data.decision)
                isProcessingRequest = false
                sendToFS("explorePotentialValueLateFinished", { time: currentMoment, gaClientId: gaClientId, forceDecision: forceDecision })
            })
    }

    const getGA = () => {
        return (window.ga && typeof window.ga.getAll == 'function' && window.ga.getAll() && window.ga.getAll().length && window.ga.getAll()[0] ? window.ga.getAll()[0].get('clientId') : undefined)
    }

    const getSessionData = () => {
        return JSON.parse(window.getCookie('__utmsession') || null)
    }

    const getDebugData = (userId) => {
        const debugData = {}
        let sessionData = getSessionData()

        try {
            if (typeof sessionData != 'object') {
                debugData.sessionData = sessionData
                sessionData = {}
            }
            debugData.cookiesEnabled = navigator ? navigator.cookieEnabled : 'no navigator object'
            debugData.utmCookie = window.getCookie('__utmzz')
            debugData.ga_clientId = getGA()
            debugData.userId = userId ? userId.toString() : debugData.ga_clientId

            if (sessionData && !sessionData.gaclient) {
                debugData.missingGaClient = true
            }
        }
        catch (err) {
            debugData.failure = err
                debugData.ga = ga
                debugData.gaGetAll = ga && typeof ga.getAll == 'function' && ga.getAll() && ga.getAll().length && ga.getAll()[0]
        }

        return debugData
    }

    const isItTimeOutAlready = () => {
        const currentMoment = new Date()
        return currentMoment.getTime() - hpPresentedAt > TIMEOUT_EXPLORE_VALUE_CTA
    }

    const sendToFS = (eventName, payload) => {
        const MAX_RETRY = 3
        const FS_RETRY_TIMEOUT = 500

        if (window['_fs_namespace'] && window[window['_fs_namespace']] && window[window['_fs_namespace']].event) {
            window[window['_fs_namespace']].event(eventName, payload)
        }
        else if(sendToFsTake < MAX_RETRY) {
            sendToFsTake = sendToFsTake + 1
            setTimeout(() => sendToFS(eventName, payload), FS_RETRY_TIMEOUT);
        }
        else {
            console.log(`Sending to FS failed, eventName ${eventName}, payload: ${JSON.stringify(payload)}`)
        }
    }

    const retrySend = (forceDecision) => {
        const MAX_RETRY_FOR_SESSION_DATA = 3
        const SESSION_TRY_TIMEOUT = 200

        const UTMSessionCookie = window.getCookie('__utmsession') || '{}'
        const sessionCookieExists = UTMSessionCookie !== '{}'
        console.log(`'__utmsession' cookie not exists yet. retry: ${getSessionDataTake}`)

        if (sessionCookieExists) {
            try {
                const gaClientId = getGA()
                const cachedDecision = localStorage.getItem(LS_CACHE_KEY_PREFIX + gaClientId)

                // retry to get gaClientId until TIMEOUT_EXPLORE_VALUE_CTA exceeds
                if (cachedDecision) {
                    const finalDecision = (forceDecision && forceDecision.split('_')[1]) || cachedDecision
                    return revealCTA(finalDecision, true)
                }
                if (!isItTimeOutAlready()) {
                    if (gaClientId) {
                        getPersonalizerDecision(gaClientId, forceDecision, timeoutP13NRequest)
                    } else {
                        setTimeout(() => retrySend(forceDecision), 100)
                    }
                } else {
                    getPersonalizerDecision(gaClientId || null, forceDecision, timeoutP13NRequest)
                }
            } catch (e) {
                if (getSessionDataTake <= MAX_RETRY_FOR_SESSION_DATA) {
                    getSessionDataTake = getSessionDataTake + 1
                    setTimeout(() => retrySend(forceDecision), SESSION_TRY_TIMEOUT)
                } else {
                    const fallbackDecision = 'dontShow'
                    PubSub.publish(PUB_SUB_EVENT, {
                        decision: fallbackDecision,
                        url: getTargetUrl(fallbackDecision),
                    })
                }
            }
        }
        else {
            getSessionDataTake = getSessionDataTake + 1
            timeoutP13NRequest = timeoutP13NRequest + SESSION_TRY_TIMEOUT
            setTimeout(() => retrySend(forceDecision), SESSION_TRY_TIMEOUT);
        }
    }

    const getPersonalizerDecision = (gaClientId, forceDecision, additionalTimeout) => {
        if (isProcessingRequest) {
            return
        }
        let forceDecisionValue = forceDecision
        const sessionData = getSessionData()
        const payload = {
            gaclient: gaClientId,
            visitorType: 'visitor',
            session: sessionData,
            debugData: getDebugData()
        }
        if (forceDecision) {
            forceDecisionValue = forceDecision.split('_')[1] // Format is the following /?forceDecision=showExplorePotentialValue_show
            payload.session.forceDecision = forceDecisionValue
        }
        sendToFS('explorePotentialValueRequestStart', { time: currentMoment, gaClientId: gaClientId, forceDecision: forceDecisionValue })
        isProcessingRequest = true
        axios.post(`${window.PERSONALIZER_URL}/explorePotentialValue`, payload)
            .then((res)=> {
                const {decision} = res.data
                const currentMoment = new Date()

                localStorage.setItem(LS_CACHE_KEY_PREFIX + gaClientId, decision)
                sendToFS('explorePotentialValueRequestFinished', { time: currentMoment
                    , gaClientId: gaClientId, forceDecision: forceDecisionValue, hpPresentedAt: hpPresentedAt, additionalTimeout: additionalTimeout })

                if (isItTimeOutAlready()) {
                    updateDecisionDontShow(gaClientId, forceDecision)
                } else {
                    revealCTA(decision)
                    isProcessingRequest = false
                }
            })
            .catch((err) => {
                console.error('throw ERROR', err)
            })

    }

    const revealCTA = (decision, fromCache = false) => {
        if (fromCache) {
            $(document).on('ready', () => {
                // I haven't found more elegant solution since coffee script is subscribed to Document.ready
                // and in case of cached result this snippet executes before coffee's callback subscribes so...
                PubSub.publish(PUB_SUB_EVENT, { decision, url: getTargetUrl(decision) })
            })
            // Sometimes document.ready already happened at this point
            PubSub.publish(PUB_SUB_EVENT, { decision, url: getTargetUrl(decision) })
        } else {
            PubSub.publish(PUB_SUB_EVENT, { decision, url: getTargetUrl(decision) })
        }
        if ( decision === 'dontShow') {
            return
        }
        setShowButton(true)
        setDecision(decision)
        pushGAEvent('presented')
    }

    const getTargetUrl = (decision) => {
        const flows = {
            show: 'explore_value_trends',
            showRecentAuctionsFlow: 'explore_value_recent_deals',
            showLightTrendsFlow: 'explore_value_light_trends',
        }
        return appUrl(props.app_url, `flow=${flows[decision]}`)
    }

    const pushGAEvent = (action) => {
        dataLayer.push({
            'event':'GTM event To GA',
            'GA_event_category': 'rings',
            'GA_event_action': `Explore potential value CTA ${action} - Rings`,
            'GA_event_label': 'Homepage'
        })
    }

    const appUrl = (path, queryParams) => {
        const params = window.location.search ? new URLSearchParams(window.location.search) : ''
        let queryString = params ? (queryParams ? `${params}&${queryParams}` : params) : (queryParams ? queryParams : '')
        return `${path}${queryString ? `?${queryString}` : ''}`
    }

    const onClick = () => {
        pushGAEvent('clicked')
        goTo(getTargetUrl(decision))
    }

    const buttonText = 'Explore potential value'

    return (
        showButton ? (isMobile() ?
            <LargeLinkButton text={buttonText} click={ onClick } />
            : <LargeSecondaryButton text={buttonText} click={ onClick }/> ) : null)
}

export default ExplorePotentialValue