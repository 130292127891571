import React from 'react';
import CdnPath from "../../services/cdn_path";
const svgShapes = {
    asscher: 'shared/shapes/asscher.svg',
    cushion: 'shared/shapes/cushion.svg',
    emerald: 'shared/shapes/emerald.svg',
    marquise: 'shared/shapes/marquise.svg',
    oval: 'shared/shapes/oval.svg',
    pear: 'shared/shapes/pear.svg',
    princess: 'shared/shapes/princess.svg',
    radiant: 'shared/shapes/radiant.svg',
    round: 'shared/shapes/round.svg'
};

class OptionShape extends React.Component {

    constructor(props) {
      super(props)
      this.select = this.select.bind(this)
    }

    select() {
        this.props.selectOption(this.props.value);
    }

    render() {
        let classname = this.props.value.selected ? 'selected' : '',
            shape = this.props.value['pretty_name'].replace(/ /, '-').toLowerCase()
        ;

        return (
            <li className={`shape ${classname} shape-${shape}`}
                onClick={this.select}>
              <div className="icon-wrap">
                  {svgShapes[shape] ? (<img width="100%" src={CdnPath(svgShapes[shape])}/>) : (this.props.value['pretty_name']) }
              </div>
              <span className="desc">
                  {this.props.value['pretty_name']}</span>
            </li>
        )
    }
}

export default OptionShape;