import React from 'react';
import _ from 'lodash';

import {
    SHAPES,
    deepClone,
    texts
} from '../filters/filter_constants_and_helpers';

import OptionShape from './option_shape'

class FilterFlat extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            options: this.selectedOptions(this.props),
            optionLookup: this.optionsLookup()
        }
        this.selectOption = this.selectOption.bind(this)
        this.setSearchFilters = this.setSearchFilters.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedOptions !== nextProps.selectedOptions) {
            this.setState({
                options: this.selectedOptions(nextProps)
            });
        }
    }

    // create options lookup by option (shape) id for options array, in the form of {optionID: optionIndex, ...}
    //   e.g {"8":0,"9":1,"10":2, ...}
    optionsLookup() {
        let lookup = {};

        _.each(this.props.options['jewelry'], (v, i) => {
            lookup[v.id] = i;
        });

        return lookup;
    }

    selectedOptions(props) {
        let options = deepClone(props.options['jewelry']),
            selected = props.selectedOptions;
        // reset selected options
        _.each(options, (o, i) => {
            if (selected === 'All') return;

            options[i].selected = _.includes(selected, o['id']) ||
                selected.some(r => SHAPES['other'].includes(r)) && o['system_name'] === 'other';
        });

        return options;
    }

    // collect selected options, send list of pretty names
    setSearchFilters() {
        let values = _.filter(this.state.options, {selected: true})
            .map((val) => {
                let isOther = val['system_name'] === 'other'; // special case for 'other', send rest of shapes
                return isOther ? SHAPES['other'] : val['id'];
            });

        this.props.setFilters(this.props['systemName'], values.flat());
    }

    selectOption(option) {
        let options = deepClone(this.state.options),
            idx = this.state.optionLookup[option.id];

        options[idx].selected = !options[idx].selected; // toggle option state
        this.setState({options: options},
            this.setSearchFilters);
    }

    selectedCount() {
        return _.filter(this.state.options, {selected: true}).length;
    }

    render() {
        let children =
                this.state.options.map((value, i) => {
                    return <OptionShape key={i}
                                        selectOption={this.selectOption}
                                        value={value}/>
                }),
            count = this.selectedCount(),
            selectedClass = count ? 'has-selected' : '',
            selectedTextObj = texts['shapes_selected'],
            selectedText = count > 1 ? selectedTextObj['plural'] : selectedTextObj['single']
        ;

        return (
            <div className={`filter col-sm-12 filter-${this.props.name}`}>
                <label>{this.props.label}</label>
                <span className={`shapes-selected ${selectedClass}`}>
                    {count} {selectedText}</span>
                <div className="flat-wrap">
                    <ul className={`unstyled flat`}>
                        {children}
                    </ul>
                </div>
            </div>
        )
    }
}

export default FilterFlat;
