import React from 'react';
import {LargeLinkButton, LargeSecondaryButton} from '@worthy-npm/clarity';
import { goTo, isMobile } from '../filters/filter_constants_and_helpers.jsx.erb';


class ExploreValueCTA extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this._pushGAEvent = this._pushGAEvent.bind(this);
    }

    _pushGAEvent(action) {
        dataLayer.push({
            'event':'GTM event To GA',
            'GA_event_category': this.props.ga_category,
            'GA_event_action': `Explore potential value CTA ${action} - ${this.props.itemType}`,
            'GA_event_label': this.props.ga_label
        })
    }

    onClick() {
        this._pushGAEvent('clicked')
        goTo(this.props.goToLink)
    }

    render() {
        return isMobile() ?
            <LargeLinkButton text={this.props.text} click={ this.onClick } />
            : <LargeSecondaryButton text={this.props.text} click={ this.onClick }/>
    }
}

export default ExploreValueCTA;