import React from 'react';
import { H2 } from '@worthy-npm/clarity';


class ReadMore extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <H2 text={this.props.text}/>
        )
    }
}

export default ReadMore;