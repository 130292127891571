import React, { Fragment } from 'react';
import axios from 'axios';
import { eq, get } from 'lodash';
import { MediumSecondaryButton, H2 } from '@worthy-npm/clarity';
import RecentDealsSliderMobile from '../common/slider/RecentDealsSliderMobile';
import RecentDealsSliderDesktop from '../common/slider/RecentDealsSliderDesktop';

import { isMobile, goTo } from '../filters/filter_constants_and_helpers.jsx.erb';

export const ITEMS_COUNT_FOR_SLIDER = 8;


const GA_EVENT_TYPES = {
    pastAuctionTitleClicked: label => ({
        action: 'past_auction_title_clicked',
        label
    }),
    pastAuctionClicked: label => ({
        action: 'past_auction_clicked',
        label,
    }),
    recentlySoldSectionPresented: label => ({
        action: 'Recently_sold_section_presented',
        label,
    }),
    recentlySoldScroll: () => ({
        action: 'Recently sold - scroll',
        label: null,
    }),
    recentlySoldSectionClicked: (tab, link) => ({
        action: `Recently sold ${tab} section clicked`,
        label: link
    })
};

const getSingleFormOfItemType = (type) => {
    if (type === 'loose-diamonds') {
        return 'diamond';
    } else if (type === 'earrings') {
        return type
    } else if (type === 'Watches') {
        return 'watch';
    } else {
        return type.substring(0, type.length - 1);
    }
};

class RecentDealsSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationHash: '',
            soldItems: [],
        };

        this.handleHashChange = this.handleHashChange.bind(this);
        this.fetchRecentSoldItems = this.fetchRecentSoldItems.bind(this);
        this._getProperLocationName = this._getProperLocationName.bind(this);
        this._animateRecentSoldSliderBlock = this._animateRecentSoldSliderBlock.bind(this);
    }

    componentDidMount() {
        this.setState({
            locationHash: this._getProperLocationName()
        });

        this._animateRecentSoldSliderBlock();

        window.addEventListener('hashchange', this.handleHashChange);

        this._pushGAEvent(GA_EVENT_TYPES.recentlySoldSectionPresented(this._getProperLocationName()));
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.handleHashChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isPropNotEqual = i => !eq(get(this.state, i), get(prevState, i));

        if (['locationHash'].find(isPropNotEqual)) {
            this.fetchRecentSoldItems();
        }

        if (['locationHash', 'soldItems'].find(isPropNotEqual)) {
            this._animateRecentSoldSliderBlock();
        }
    }

    fetchRecentSoldItems() { // TODO: rewrite(instead of using axios here use method from service) it when changes from recent sold page will be merged (Jira 9572)
        axios.get(`/get_recent_deals?selectedTab=${this.state.locationHash}&count=${ITEMS_COUNT_FOR_SLIDER}`).then((res) => {
            this.setState({
               soldItems: [{}, ...res.data.items.idp]
            });
        }).catch(() => {
            console.log('Something went wrong')
        })
    }

    handleHashChange(e) {
        const selectedTab = this._getProperLocationName();

        this.setState({
            locationHash: selectedTab,
        });

        this._pushGAEvent(GA_EVENT_TYPES.recentlySoldSectionPresented(selectedTab));
    }

    /*
    * Method for getting location hash
    * */
    _getProperLocationName() {
        if (window.location.hash.length == 0) {
            return 'Rings'; // for case if no hash
        } else {
            return window.location.hash.indexOf("?") !== -1
                ? window.location.hash.slice(1, window.location.hash.indexOf("?"))
                : window.location.hash.slice(1);
        }
    }

    _animateRecentSoldSliderBlock() {
        const blockRef = document.querySelector('#recentDealsOurExpertLabs') || document.querySelector('.recentDeals') || document.querySelector('.relatedItems');

        if (this._getProperLocationName() === 'Multiple_Jewelry' || this.state.soldItems.length < 4) {
            blockRef.style.overflow = 'hidden';
            blockRef.style.height = 0;

        } else {
            blockRef.style.overflow = 'visible';
            blockRef.style.height = 'auto';
        }
    }

    _pushGAEvent({ action, label }) {
        dataLayer.push({
            'event':'GTM event To GA',
            'GA_event_category':'HP marketplace snippets',
            'GA_event_action': action,
            'GA_event_label': label
        })
    }

    render() {
        const { locationHash, soldItems } = this.state;

        const headerText = `${isMobile() ? '' : 'Recent'} ${getSingleFormOfItemType(locationHash === 'Loose_Diamonds' ? 'Diamonds' : locationHash)} Auctions`;
        const linkToMarketplace = `/about/recent-deals/${locationHash.toLowerCase().replace('_', '-')}`;

        const renderHeader = () => (
            <a id="recent-deals-title" href={linkToMarketplace} style={{textAlign: 'center'}}
                onClick={() => this._pushGAEvent(GA_EVENT_TYPES.pastAuctionTitleClicked(headerText))}>
                    <H2 text={headerText}/>
            </a>
        );

        return (
            <Fragment>
                { renderHeader() }
                {/* Row for slider items */}
                {
                    isMobile()
                        ? <RecentDealsSliderMobile
                            showTimeAgo={true}
                            soldItems={soldItems}
                            itemsPerSlider={ITEMS_COUNT_FOR_SLIDER}
                            pushClickGAEvent={() => this._pushGAEvent(GA_EVENT_TYPES.pastAuctionClicked(locationHash))}
                            pushScrollGAEvent={() => this._pushGAEvent(GA_EVENT_TYPES.recentlySoldScroll())}
                        />
                        : <RecentDealsSliderDesktop
                            showTimeAgo={true}
                            soldItems={soldItems}
                            locationHash={locationHash}
                            pushClickGAEvent={() => this._pushGAEvent(GA_EVENT_TYPES.pastAuctionClicked(locationHash))}
                            pushScrollGAEvent={() => this._pushGAEvent(GA_EVENT_TYPES.recentlySoldScroll())}
                        />
                }
                {
                    this.props.showDicsoverMoreButton ?
                        <div style={{ textAlign: 'center', margin: '32px 0 60px' }}>
                            <MediumSecondaryButton
                                text="Discover More"
                                disabled={false}
                                click={() => {
                                    this._pushGAEvent(GA_EVENT_TYPES.recentlySoldSectionClicked(locationHash, linkToMarketplace));
                                    goTo(linkToMarketplace);
                                }}
                            />
                        </div> : null
                }

            </Fragment>
        )
    }
}

export default RecentDealsSlider;