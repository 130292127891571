import React from 'react';
import { MediumSecondaryButton } from '@worthy-npm/clarity';
import { goTo } from '../filters/filter_constants_and_helpers.jsx.erb';


class ReadMore extends React.Component {
    constructor(props) {
        super(props);
        this.moveUserToReviews = this.moveUserToReviews.bind(this);
    }

    componentDidMount() {
        this._pushGAEvent('presented');
    }

    _pushGAEvent(action) {
        dataLayer.push({
            'event':'GTM event To GA',
            'GA_event_category':'HP marketplace snippets',
            'GA_event_action': action,
            'GA_event_label': this.props.ga_label
        })
    }

    moveUserToReviews() {
        this._pushGAEvent('clicked');
        goTo(this.props.goToLink)
    }

    render() {

        return <MediumSecondaryButton text={ this.props.text } click={ this.moveUserToReviews }/>
    }
}

export default ReadMore;