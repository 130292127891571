import React from 'react';
import { string, number, shape } from 'prop-types';
import { Grid } from "@material-ui/core";

import { goTo } from '../filters/filter_constants_and_helpers';
import CdnPath from "../../services/cdn_path";

const styles = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#000000',
};

const DealItem = ({ deal, withGridLayout = true,  showTimeAgo = false}) => {
    const renderWithProperLayout = (content) => withGridLayout
        ? (<Grid item sm={12} xs={12} md={3} lg={3} style={styles} onClick={() => goTo(deal.item_deal_url)}> {content} </Grid>)
        : (<div style={styles} onClick={() => goTo(deal.item_deal_url)}>{content}</div>);

    /*
    * This solution add for displaying default images on QA (in order easy test it)
    * */
    const getImgUrlAccordingToENV = deal => !deal.item_image_featured && location.host !== 'www.worthy.com' ? CdnPath('frontend/recent-deals/default.jpg') : deal.item_image_featured;
    const descStyle = {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        font: '300 14px Uncut Sans',
        lineHeight: '22px'
    }

    return renderWithProperLayout(
        <div className="deal-item">
            <div className="mobile-content">
                <div className="sold-line">
                    Sold for
                    <span>{deal.amount}</span>
                </div>

                <img
                    src={getImgUrlAccordingToENV(deal)}
                    alt="Item preview"
                    style={{
                        marginBottom: '6px',
                        height: 'auto',
                        maxWidth: '100%'
                    }}
                />
            </div>


            <div className="footer-line">
                <div style={descStyle}>{deal.name}</div>
                {showTimeAgo ? <span className="time-ago" style={{color: '#919191', fontSize: '15px'}}>{deal.time_ago}</span> :''}
            </div>

        </div>
    );
};

DealItem.propTypes = {
    deal: shape({
        amount: string,
        name: string,
        item_deal_url: string,
        bids: number,
        item_image_featured: string,
        item_image_full_size: string,
        item_image: string,
    })
};

export default DealItem;
