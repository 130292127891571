import React, { useState, useEffect } from 'react'
import {XLPrimaryButton, Checkbox, LargePrimaryButton} from '@worthy-npm/clarity'
import TrustPilot from "./TrustPilot"
import { goTo } from '../filters/filter_constants_and_helpers.jsx.erb'
import axios from "axios"


const JewelryTypesModal = (props) => {
    const {texts, appUrl, siteUrl, pushEvent} = props

    const [selectedTypes, setSelectedTypes] = useState(['ring'])
    const [disableButton, setDisableButton] = useState(false)

    const jewelryTypesTexts= texts['types-list']
    const jewelryTypes= [{
        name: 'ring',
        description: jewelryTypesTexts['ring'],
        linkTo: `${appUrl}`
    }, {
        name: 'diamond',
        description: jewelryTypesTexts['diamond'],
        linkTo: `${appUrl}#Loose_Diamonds`
    }, {
        name: 'earrings',
        description: jewelryTypesTexts['earrings'],
        linkTo: `/form#itemCategories/Earrings`
    }, {
        name: 'necklace',
        description: jewelryTypesTexts['necklace'],
        linkTo: `${appUrl}/submit/necklace`
    }, {
        name: 'bracelet',
        description: jewelryTypesTexts['bracelet'],
        linkTo: `${appUrl}/submit/bracelet`
    }, {
        name: 'watch',
        description: jewelryTypesTexts['watch'],
        linkTo: `${appUrl}/submit/watch`
    }, ]

    useEffect(() => {
        pushEvent('Jewelry type modal presented')
    }, [])

    const onCTAClick= (stopAnimation) => {
        // stopAnimation()
        pushEvent('Get Started CTA clicked', `Get Started CTA clicked/${selectedTypes.join(',')}`)

        if (selectedTypes.length === 1) {
            const type = jewelryTypes.find(t => t.name === selectedTypes[0])
            goTo(type.linkTo)
        }
        else {
            const pendingItems = {source: 'hp-site', subsets: []}
            selectedTypes.map(type => pendingItems.subsets
                .push({ name: type, quantity: 1 })
            )
            axios.post(`/api/v2/sellers/jewelry_box/create_jewelry_box`,
                pendingItems, {headers: {'userToken': window.getCookie('jwt')}})
                .then((res)=> {
                    if (document.getElementById('user-logged-in-data')) {
                        goTo(`${appUrl}/multiple_jewelry/${res.data.bundle_id}`)
                    }
                    else {
                        goTo(`${appUrl}/register?bundle=${res.data.bundle_id}`)
                    }
                })
                .catch((err) => {
                    stopAnimation()
                    console.error(`Error submitting multiple jewelry from modal: ${err}`)
                })
        }
        setTimeout(() => {
            stopAnimation()
        }, 1000)
    }

    return (
            <div style={{ height: '600px', margin: '0 25px'}}>
                <div style={{fontSize: '16px', marginBottom: '30px', lineHeight: '24px'}}>{texts['sub-title']}</div>
                <div style={{textAlign: 'left', marginBottom: '30px'}}>
                {
                    jewelryTypes.map((type, index) => {
                        return <Checkbox key={`type-cb-${index}`}
                                         name={type.name}
                                         label={type.description}
                                         checked={selectedTypes.includes(type.name)}
                                         onChange={(value) => {
                                             let currentTypes = selectedTypes;
                                             if (value.target.checked){
                                                 currentTypes = selectedTypes.concat(type.name)
                                                 setSelectedTypes(currentTypes)
                                             }
                                             else {
                                                 currentTypes = selectedTypes.filter(item => item !== type.name)
                                                 setSelectedTypes(currentTypes)
                                             }
                                             setDisableButton(!currentTypes.length)
                                         }
                        } />
                    })
                }
                </div>
                <XLPrimaryButton click={(stopAnimation)=> {onCTAClick(stopAnimation)}}
                                 disabled={disableButton}
                                 text={texts['main-cta']}
                                 overrideCSS={{ width: '100%', borderRadius: '4px', fontSize: '18px', boxShadow: '0px 4px 11px 1px #e0dfdfa6'}}
                                 overrideWrapperCSS={{width: '100%'}} />
                <div style={{margin: '20px 0'}}>
                    <TrustPilot lightTheme={true} />
                </div>
            </div>
    )
}

export default JewelryTypesModal