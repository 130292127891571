import React from 'react';
import { Regular } from '@worthy-npm/clarity';


class ReadMore extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Regular text={this.props.text}/>
        )
    }
}

export default ReadMore;