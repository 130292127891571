import React, { useState, useRef, useEffect } from 'react';

import DealItem from '../../sellers/DealItem';

const RecentDealsSliderMobile = ({ soldItems, itemsPerSlider, pushClickGAEvent, pushScrollGAEvent, showTimeAgo }) => {
    const [activeElementIndex, setActiveElementIndex] = useState(0);
    const [deltaOnTouchMove, setDeltaOnTouchMove] = useState(0);
    const [userTouchInitialPos, setUserTouchInitialPos] = useState(0);
    const [touchMoveLocked, setTouchMoveLocked] = useState(false);
    const [availableWidthToMove, setAvailableWidthToMove] = useState(0); // max width to left and rigth which we can move.
    const wrapRef = useRef(null);

    useEffect(() => {
        document.addEventListener('webkitScroll', () => {
            setTouchMoveLocked(true);
            _setBodyOverflow('visible');
        });
        document.addEventListener('scroll', () => {
            setTouchMoveLocked(true);
            _setBodyOverflow('visible');
        });
    });

    const handleMobileTouchStart = (index, e) => {
        setDeltaOnTouchMove(0);
        setTouchMoveLocked(false);

        _changeTransitionForWrapper('none');

        // need get element by id all block
        const { width } = document.getElementById(`item-${activeElementIndex}`) // wrapper has id for active item
            .getBoundingClientRect();

        setAvailableWidthToMove((window.innerWidth - width) / 2);

        setUserTouchInitialPos(e.changedTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        if (touchMoveLocked) {
            return;
        }
        const delta = Math.round(e.changedTouches[0].clientX - userTouchInitialPos);

        _setBodyOverflow('hidden');

        if (delta < availableWidthToMove && delta > availableWidthToMove * -1) { // negative value it in case if user scroll to the left
            setDeltaOnTouchMove(Math.round(e.changedTouches[0].clientX - userTouchInitialPos))
        }
    };

    const handleMobileTouchEnd = (index, e) => {
        _setBodyOverflow('visible');

        if (!userTouchInitialPos) {
            return;
        }

        pushScrollGAEvent();

        setActiveElementIndex(index);

        _changeTransitionForWrapper('transform 300ms linear');

        const direction = Math.sign(e.changedTouches[0].clientX - userTouchInitialPos); // positive or negative sign

        if (direction < 0 && activeElementIndex < itemsPerSlider - 1) { // right
            setActiveElementIndex(activeElementIndex + 1);
        } else if (direction > 0 && activeElementIndex > 0) { // left
            setActiveElementIndex(activeElementIndex - 1);
        }

        setUserTouchInitialPos(null);
        setDeltaOnTouchMove(0);
    };

    const _changeTransitionForWrapper = (prop) => wrapRef.current.style.setProperty('transition', prop);
    const _setBodyOverflow = (prop) => document.body.style.overflow = prop;

    const getTransformValue = () => `translateX(calc(-${activeElementIndex * 55}vw - ${activeElementIndex * 14}px + ${deltaOnTouchMove - 7}px))`;

    return (
        <div style={{ overflow: 'hidden', marginBottom: '10px' }}>
            <div className={`deals-slider mobile active-slide-${activeElementIndex}`}>
                <div
                    ref={wrapRef}
                    className="deals-slider-wrapper"
                    style={{
                        transform: getTransformValue(),
                        MozTransform: getTransformValue(),
                        msTransform: getTransformValue(),
                        OTransform: getTransformValue(),
                        WebkitTransform: getTransformValue(),
                    }}
                >
                    {
                        soldItems.slice(1).map((deal, i) => (
                            <div
                                className={`item ${activeElementIndex === i ? `active` : ''}`}
                                id={`item-${i}`}
                                key={deal.name}
                                onTouchStart={(e) => handleMobileTouchStart(i, e)}
                                onTouchEnd={(e) => handleMobileTouchEnd(i, e)}
                                onTouchMove={handleTouchMove}
                                onPointerEnter={() => pushClickGAEvent(deal)}
                            >
                                <DealItem
                                    deal={deal}
                                    withGridLayout={false}
                                    showTimeAgo={showTimeAgo}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default RecentDealsSliderMobile;
