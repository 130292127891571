import React, { useState, useEffect } from 'react'
import {DropDown, DropItem, XLPrimaryButton, ModalPattern} from '@worthy-npm/clarity'
import TrustPilot from "./TrustPilot"
import JewelryTypesModal from './JewelryTypesModal'
import { goTo } from '../filters/filter_constants_and_helpers.jsx.erb'
import CdnPath from "../../services/cdn_path";


const AboveTheFold = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const {texts, appUrl, siteUrl} = props

    const pushGAEvent = (eventAction, eventLabel = null) => {
        dataLayer.push({
            'event':'GTM event To GA',
            'GA_event_category': 'HP above fold section',
            'GA_event_action': eventAction,
            'GA_event_label': eventLabel
        })
    }

    const onClickCTA = (stopAnimation) => {
        pushGAEvent('Get Started CTA clicked', 'Get Started CTA clicked/ring')
        goTo(appUrl)
        setTimeout(() => {
            stopAnimation()
        }, 1000)
    }

    const onClickDropDown = (e) => {
        console.log('Dropdown clicked')
        setOpenModal(true)
        pushGAEvent('Dropdown clicked')
    }

    return (
        <div style={{marginBottom: '30px'}}>
            <div style={{textAlign: 'center', margin: '0 49px 20px'}}>
                <div
                    style={{lineHeight: '56px', fontSize: '54px', fontWeight: '900', fontFamily: "Playfair Display", margin: '10vmin auto 3vmin'}}>
                    {texts['title']}
                </div>
                <div
                    style={{fontSize: '18px', marginBottom: '13vmin', }}>
                    {`"${texts['sub-title']}"`}
                </div>
                    <div className={'dropdown'}
                         onClick={onClickDropDown}
                         style={{marginBottom: '20px', textAlign: 'left', border: '1px solid #aaa', borderRadius: '4px', fontSize: '16px', boxSizing: ' border-box', padding: '13px 18px', position: 'relative'}}>
                        <span
                            style={{display: 'block', padding: '0px 6px', position: 'absolute', top: '0px', transform: 'translate(6px, -12px) scale(0.9)', backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(145, 145, 145)', left: '0px'}}>
                            List for sale
                        </span>
                        Ring / ring set
                        <img src={CdnPath("backend/icons/blue_arrow.svg")}
                             style={{position:"absolute", right: '15px', top: '20px'}} />
                    </div>
                <XLPrimaryButton text={texts['main-cta']}
                                 click={onClickCTA}
                                 overrideCSS={{backgroundColor: "#007BEC", width: '100%', borderRadius: '4px', fontSize: '18px', boxShadow: '0px 4px 11px 1px #e0dfdfa6'}}
                                 overrideWrapperCSS={{width: '100%'}} />
            </div>
            <TrustPilot lightTheme={true} />
            { openModal ?
                <ModalPattern
                    key={'ModalPattern'}
                    textAlign={'center'}
                    title={ texts['jewelry-types-modal']['title'] }
                    closeIcon={ true }
                    closeOnClickOutside={ false }
                    closeOnEscape={ false }
                    isWide={ true }
                    overrideCSS={{maxHeight: '93%', zIndex: 2147483005}}
                    onClose={ () => {
                        setOpenModal(false)
                    }}>
                    <JewelryTypesModal texts={texts['jewelry-types-modal']} appUrl={appUrl} siteUrl={siteUrl} pushEvent={pushGAEvent} />
                </ModalPattern> : null}
        </div>
    )
}

export default AboveTheFold