import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import {
    deepClone,
    color_values,
    clarity_values,
    avg_color_values,
    avg_clarity_values,
} from '../filters/filter_constants_and_helpers';

const RANGES = {
    color: [0, 9],
    clarity: [0, 10],
    carat_weight: [0.1, 20]
};

// FIXME: if we try to start with an empty array it crashes on desktop, but
// FIXME:   it might also crash on mobile
// variant steps for clarity slider to match letter spacing

// FIXME: NOAM I'm not sure if we need this from the server. I expect it to be from the component declaration in the code
// let props = "<%= Objects.properties_values.to_json %>",
const props = {
    clarity: clarity_values,
    color: color_values,
    average_clarity_grade: avg_clarity_values,
    average_color_grade: avg_color_values
};

let clarityValues = [0, 0.7, 1.7, 3.3, 4.4, 5.6, 6.6, 7.7, 8.5, 9.2, 10];

// this component contains 2 diff types of sliders,
//   'numeric' (Carat) and 'letters' (Clarity and Color)
// TODO: either split into 2 diff components or
//  merge methods
class FilterSlider extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            options: [],
            optionLookup: {},
            range: RANGES[props.name],
            initFunc: this.initFunc.bind(this),
            initialized: false,
            clarityValues: [],
            colorFancyChecked:props.selectedOptions.endsWith("-fancy")
        };
        this.onNumericChange = this.onNumericChange.bind(this)
        this.handleFancyCheck = this.handleFancyCheck.bind(this)
        this.initLetter = this.initLetter.bind(this)
        this.initNumeric = this.initNumeric.bind(this)
        this.initLetter = this.initLetter.bind(this)
        this.onLetterSlide = this.onLetterSlide.bind(this)
        this.letterBuildRange = this.letterBuildRange.bind(this)
    }

    componentDidMount() {
        this.state.initFunc();
        this.setState({initialized: true});
        if (this.isClarity()) {
            clarityValues = this.clarityOffsets();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let node = ReactDOM.findDOMNode(this),
            $slider = $(node).find('.slider').first(),
            selectedOptions = this.selectedOptions(),
            caratPow = 0.4;

        // if color check if fancy state changed.
        if ( this.isColor() && prevState.colorFancyChecked !== this.props.selectedOptions.endsWith("-fancy")) {
            this.setState({colorFancyChecked:this.props.selectedOptions.endsWith("-fancy")})
        }

        // dynamically calc letter offsets.
        // FIXME: i think we need to setState so it will re-render
        if (this.isClarity()) { // && !_.isEqual(prevState.clarityValues, clarityValues)) {
            clarityValues = this.clarityOffsets();
            // this.setState({clarityValues: clarityValues})
        }

        // Initialize sliders
        if (!this.state.initialized) {
            this.setState({initialized: true});
            this.state.initFunc();
        }

        // set slider values
        if (this.props.selectedOptions !== prevProps.selectedOptions) {
            if (this.isCarat()) {
                selectedOptions = this.numericSliderLog(selectedOptions, caratPow)
            }
            if (this.isClarity()) {
                selectedOptions = this.clarityTranslateValues();
            }
            $slider.slider('values', selectedOptions);
        }
    }

    clarityTranslateValues(values, reverse = false) {
        return _.map(values || this.selectedOptions(), (i) => {
            return reverse ? clarityValues.indexOf(i) : clarityValues[i];
        });
    }

    // dynamically calc letter offsets.
    // (the percentile offsets change with device resolution, prob due to flexbox behavior)
    clarityOffsets() {
        let node = ReactDOM.findDOMNode(this),
            $wrap = $(node).find('.ui-slider'),
            wrapWidth = $wrap.width(),
            wrapOffset = $wrap.offset().left,
            $guides = $(node).find('.guide'),
            offsets = [],
            numOfSteps = 10;

        _.each($guides, (el) => {
            let offset = $(el).offset().left,
                width = $(el).width(),
                off = (offset + (width / 2) - wrapOffset) / wrapWidth * numOfSteps;
            if (off < 0) off = 0;
            else if (off > numOfSteps) off = numOfSteps;
            offsets.push(Math.round(off * 10) / 10);
        });

        return offsets;
    }

    isClarity() {
        return this.isType('clarity');
    }

    isColor() {
        return this.isType('color');
    }

    isCarat() {
        return this.isType('carat_weight');
    }

    isType(type) {
        return this.props.name === type;
    }

    // dynamically get init func
    initFunc() {
        let subtype = this.props.subtype,
            capitalized = subtype[0].toUpperCase() + subtype.slice(1);

        return this['init' + capitalized]();
    }

    // it makes the slider move non-linear
    numericSliderLog(valArrByRef, pow, max, precision) {
        let power = (val) => {
                return parseFloat(Math.pow(val / max, pow) * max);
            },
            precize = (val) => {
                return Math.round(val * precision) / precision;
            },
            valArr = deepClone(valArrByRef);
        max = max || this.state.range[1];
        precision = Math.pow(10, precision || 2);

        valArr[0] = power(valArr[0]);
        valArr[1] = valArr[1] ? power(valArr[1]) : 0;

        return [
            precize(valArr[0]),
            precize(valArr[1])
        ];
    }

    // used for 'numeric' type slider, such as Carat
    // API: [http://api.jqueryui.com/slider/#event-slide]
    // ui [Type: Object]
    //     handle [Type: jQuery]
    //         The jQuery object representing the handle being moved.
    //     handleIndex [Type: Number]
    //         The numeric index of the handle being moved.
    //     value [Type: Number]
    //         The value that the handle will move to if the event is not canceled.
    //     values [Type: Array]
    //         An array of the current values of a multi-handled slider.
    onNumericChange(event, ui) {
        let $handle = $(ui.handle),
            mul = 2.5,
            values = this.numericSliderLog([ui.value], mul);

        $handle.find('.cite').text(values[0] + ' ct');
    }

    arrToFromHash(arr) {
        return {from: arr[0], to: arr[1]};
    }

    // used for 'numeric' type slider, such as Carat
    initNumeric() {
        let totalRange = deepClone(this.state.range),        // total avail range (min,max)
            step = 0.01,
            pow = 0.4,
            rangeValues = this.selectedOptions(),
            rangeValuesLog = this.numericSliderLog(rangeValues, pow),
            minMax = this.numericSliderLog(totalRange, pow, totalRange[1]),
            _this = this,
            $slider = $('.filter-carat_weight').find('.slider').first();

        // TODO: merge inits to single method
        $slider.slider({
            range: true,
            step: step,
            min: minMax[0],
            max: totalRange[1],
            // behind the scenes we're working with log values
            values: rangeValuesLog,
            slide: this.onNumericChange,    // Triggered on every mouse move during slide. The value provided
                                            // in the event as ui.value represents the value that the handle will
                                            // have as a result of the current movement. Canceling the event will
                                            // prevent the handle from moving and the handle will continue to have its previous value.

            change: this.onNumericChange,   // Triggered after the user slides a handle, if the value has
                                            // changed; or if the value is changed programmatically via the value method.
            stop: function (event, ui) {
                let mul = 2.5, values = _this.numericSliderLog(ui.values, mul);
                _this.props.setFilters(_this.props['systemName'], _this.arrToFromHash(values));
            },
            animate: true,
            create: function () {
                let $handle = $(this).find('.ui-slider-handle'),
                    $cite = $('<span/>').addClass('cite'),
                    $shadow = $('<span/>').addClass('shadow');

                $cite.text(rangeValues[0] + ' ct').appendTo($handle.first());
                $cite.clone().text(rangeValues[1] + ' ct').appendTo($handle.last());
                $shadow.appendTo($handle.first()).clone().appendTo($handle.last());

                $('<div/>').addClass('fake-ui').prependTo(this);
            }
        });
    }

    // used for 'letter' type slider, such as Clarity and Color
    onLetterSlide(event, ui) {
        // FIXME: for some reason onChange is triggers more times than it should
        // when fancy is enabled, disable the slider
        if (this.fancy()) return false;

        // fake variant steps
        if (this.isClarity()) {
            if (!~clarityValues.indexOf(ui.value)) return false;
        }
    }

    // create possible range permutations
    // @param [Hash] values {'from', 'to'}
    letterBuildRange(values) {
        // FIXME: Noam - check after deciding on API.
        // So far it's required for `color` and `clarity`
        let propsData,
            avgPropsData;

        switch (this.props.name) {
            case 'clarity':
                propsData = clarity_values;
                avgPropsData = avg_clarity_values;
                break;
            case 'color':
                propsData = color_values;
                avgPropsData = avg_color_values;
                break;
        }

        let range = this.props.data.slice(values['from'], values['to'] + 1);

        let rangeX = range.reduce((acc, cur, i, src) => {
            acc.push(propsData[cur], avgPropsData[cur]);
            if (src.length > i + 1) {
                let rangeVal = propsData[cur + "-" + src[i + 1]];
                if (rangeVal) {
                    acc.push(rangeVal, avgPropsData[cur + "-" + src[i + 1]])
                }
            } else {
                let prev = cur.split('-')[0];
                let lastValues = [avgPropsData[prev], propsData[prev], propsData[src[i - 1] + "-" + prev], avgPropsData[src[i - 1] + "-" + prev]];
                acc = acc.concat(lastValues);
            }
            return acc
        }, []);

        return _.compact(rangeX)
    }


    // create possible range permutations
    // @param [Hash] values {'from', 'to'}

    // used for 'letter' type slider, such as Clarity and Color
    initLetter() {
        let _this = this,
            range = this.state.range,
            rangeValues = this.selectedOptions(),
            step = this.isClarity() ? 0.1 : 1,
            $slider = $('.filter-' + this.props.name).find('.slider');

        rangeValues = this.isClarity() ? this.clarityTranslateValues() : rangeValues.slice(0, 2);

        // TODO: do repeated inits cause multiple event binding?
        $slider.slider({
            range: true,
            step: step,
            min: range[0],
            max: range[1],
            values: rangeValues,
            slide: _this.onLetterSlide,
            stop: function (event, ui) {
                // when fancy is enabled, disable the slider
                if (_this.fancy()) return false;

                let values = _this.arrToFromHash(ui.values),
                    selectedOpts = _this.selectedOptions();

                // translate values
                if (_this.isClarity()) {
                    let vals = _this.clarityTranslateValues(ui.values, true);

                    vals = _.map(ui.values, (v, i) => {
                        return (v === selectedOpts[i]) ? values[i] : vals[i]; // don't re-translate
                    });
                    values = _this.arrToFromHash(vals);
                }

                values['in'] = _this.letterBuildRange(values);
                _this.props.setFilters(_this.props['systemName'], values);
            },
            animate: true,
            create: function () {
                let $slider = $(this),
                    $handles = $slider.find('.ui-slider-handle'),
                    $guide = $slider.next();

                _.each($handles, (el) => {
                    $('<span/>').addClass('shadow').appendTo(el)
                });

                // do a single DOM insert
                _this.props.data.forEach((value) => {
                    $('<span/>')
                        .addClass('guide').text(value).appendTo($guide);
                });

                $('<div/>').addClass('fake-ui').prependTo(this);
            },
        });

    }

    //FIXME: why is this here? do we still need it?
    handleFancyCheck(e) {
        let checked = $(e.target).is(':checked'),
            opts = this.arrToFromHash(this.selectedOptions()),
            values = {in: checked ? color_values['Fancy'] : this.letterBuildRange(opts)};

        this.setState({colorFancyChecked:!this.state.colorFancyChecked})

        this.props.setFilters(this.props['systemName'], values);
    }

    selectedOptions() {
        return this.props.selectedOptions.split('-');
    }

    fancy() {
        let selected = this.isColor() && this.selectedOptions();

        return selected && (selected[2] === 'fancy');
    }

    render() {
        let data, check,
            isFancy = this.state.colorFancyChecked,
            classname = isFancy ? 'fancy' : '';

        if (this.isClarity() || this.isColor()) {
            data = <div className="data"/>;
        }
        if (this.isColor()) {
            check = (
                <div className="checkbox fancy">
                    <label>
                        <input type="checkbox"
                               checked={isFancy}
                               onChange={this.handleFancyCheck}/>
                        Fancy
                    </label>
                </div>
            );
        }

        return (
            <div className={`filter col-sm-4 filter-${this.props.name} ${classname}`}>
                <div className="slider-wrap">
                    <label>{this.props.label}</label>
                    <div className="slider"/>
                    {data}
                    {check}
                </div>
            </div>
        )
    }
}

export default FilterSlider;