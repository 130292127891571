import React from 'react';
import CdnPath from "../services/cdn_path";

const mobilePopupStyle = {
    position:"fixed",
    top:"0px",
    left:"0px",
    zIndex:999,
    width:window.innerWidth,
    height:"100%",
    boxSizing:"border-box",
    padding:"25px 25px 0px 25px",
    background: "rgba(0,0,0,0.5)",
    overflow:"scroll"
}

let scrollCatcher = {
    height:"500%",
    width:"100%"
}

let mobilePopupPaddingHelper = {
    minHeight:"300px",
    maxHeight:"565px",
    overflow:"scroll",
    left: "50%",
    top: "50%",
    transform: "translate(0, -50%)",
    WebkitTransform: "translate(0, -50%)",
    backgroundColor:"white",
}

let mobilePopupContentWrapper = {
    padding:"36px 36px 27px 36px",
    height:"100%"
}

let closeButtonWrapper = {
    height:"12px",
    position:"relative"
}
let closeButtonButton = {
    width:"24px",
    height:"24px",
    position:"absolute",
    right:"-5px",
    top:"-5px",
    background: `url(${CdnPath('frontend/X.svg')}) no-repeat left 5px top 5px`,
    backgroundSize:"14px 14px"
}

function stopPropagation(e) {
    e.stopPropagation()
    e.preventDefault()
}

class MobilePopup extends React.Component {
    constructor() {
        super()
        this.closePopup = this.closePopup.bind(this)
        $("body").addClass("no-scroll")
    }

    closePopup() {
        $("body").toggleClass("no-scroll",false)
        this.props.onClose()
    }

    render() {
        return <div style={mobilePopupStyle} onClick={this.closePopup} onTouchStart={stopPropagation}>
            <div style={scrollCatcher}>
                <div style={mobilePopupPaddingHelper} className={"position-sticky"} onTouchStart={stopPropagation}>
                    {/* we need an extra div to respect the fixed parent div padding.. */}
                    <div style={mobilePopupContentWrapper} onClick={stopPropagation} onTouchStart={stopPropagation}>
                        <div style={closeButtonWrapper} onTouchStart={stopPropagation}>
                            <div style={closeButtonButton} onClick={this.closePopup}></div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default MobilePopup;
