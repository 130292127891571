import React from 'react';
import _ from 'lodash';
import MobilePopup from '../mobile_popup';
import { deepClone, isMobile } from '../filters/filter_constants_and_helpers';

class FilterSelect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active:false,
            visible: false,
            options: this.options(),
            optionLookup: this.optionsLookup(),
            lastWeight: this.selectedValues(this.props).length + 1,
            isMobile: isMobile()
        }
        this.selectOption = this.selectOption.bind(this)
        this.setSearchFilters = this.setSearchFilters.bind(this)
        this.toggleSelectPanel = this.toggleSelectPanel.bind(this)
        this.selectAll = this.selectAll.bind(this)
        this.renderCheckbox = this.renderCheckbox.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        let clicked = !_.isEqual(this.props.clicked, nextProps.clicked),
            selected = this.selectedValues(nextProps),
            routeChanged = this.props.route !== nextProps.route,
            optsChanged = this.props.selectedOptions !== nextProps.selectedOptions
        ;
        // close dropdown if clicked outside
        if(clicked && this.state.visible) {
            this.toggleSelect();
        }

        if(routeChanged || optsChanged) {
            this.setState({
                options: this.options(nextProps),
                optionLookup: this.optionsLookup(nextProps),
                lastWeight: this.state.lastWeight || selected.length
            })
        }
    }

    selectedValues(props) {
        return props.selectedOptions
    }

    optionsLookup(props) {
        let lookup = {};

        _.each((props || this.props).options, (v, i) => {
            lookup[v.id] = i;
        });

        return lookup;
    }

    // @param [Array] opts pretty name list of selected opts, e.g ['Ring', 'Earrings', ..]
    options(props) {
        props = props || this.props;
        let selected = this.selectedValues(props),
            filterChanged = this.props.name !== props.name,
            options = deepClone((!filterChanged && this.state || props).options)
        ;
        // reset selected options
        // TODO: we need to reset weight?
        _.each(options, (o, i) => {
            let weight = _.indexOf(selected, o['id']),
                isSelected = !!~weight
            ;
            options[i].selected = isSelected;
            if(!o.weight) {
                options[i].weight = isSelected ? weight : null;
            }
        });

        return options;
    }

    setOptionState(option, state) {
        let options = deepClone(this.state.options),
            idx = this.state.optionLookup[option.id],
            weight = this.state.lastWeight
        ;
        options[idx].selected = state;

        // set weighting for tags ordering, assign highest score to latest (current) selected tag
        //   we want the last selected tag added at the end, FIFO
        if(state === true) {
            options[idx].weight = weight;
            weight++;
        }
        this.setState({
            options: options,
            lastWeight: weight
        }, this.setSearchFilters);
    }

    // collect selected options, send list of pretty names
    setSearchFilters() {
        const values = this.state.options.filter(f=>f.selected === true).sort(f=>f.weight).map(f=>f.id);
        this.props.setFilters(this.props['systemName'], values);
    }

    allSelected() {
        return this.selectedCount() === this.props.options.length;
    }

    selectedCount() {
        return _.filter(this.selectedValues(this.props), (v) => {
            return v !== 'All' && !_.isEmpty(v);
        }).length
    }

    selectedAny() {
        return this.selectedCount();
    }

    toggleSelectPanel(e) {
        // in mobile we do not need the event handling since we have an X button.
        if (!this.state.isMobile) {
            if (!this.state.active) {
                setTimeout(() => {
                    $(window).on("click",this.toggleSelectPanel)
                },0)
            } else {
                $(window).off("click",this.toggleSelectPanel)
            }
        }
        this.setState({active:!this.state.active})
    }

    selectAll(e) {
        let options = this.state.options.map(o => {
            o.selected = false
            return o
        })

        this.setState({options},this.setSearchFilters)
        if ( e ) {
            e.stopPropagation()
        }
    }

    selectOption(e) {
        let selected = this.props.options.find(x => x.id === parseInt(e.target.getAttribute("data-id")))
        let value = !this.props.selectedOptions.includes(selected.id)
        this.setOptionState(selected,value)
        e.stopPropagation()
    }

    getSelectedString() {
        let that = this
        if ( this.props.selectedOptions === 'All' || !this.props.selectedOptions.length ) {
            return 'All'
        }
        let optionNames = this.props.selectedOptions.map(x => {
            let option = that.props.options.find(o => o.id === x)
            return option? option.pretty_name: null
        })

        return optionNames.join(", ")
    }

    renderMobileSelectPanel(allSelected) {
        if ( !this.state.active ) {
            return null
        }
        // TODO: hack here for quick fix on the watch brand filter.
        // when we have more than 20 items - use just the left column.
        let leftColumnSize = this.props.options.length >= 20 ? this.props.options.length : this.props.name === 'jewelry_brands' ? 7 : 5; // handle specific case for brands filter
        let leftColumnData = this.props.options.slice(0,leftColumnSize)
        let rightColumnData = this.props.options.slice(leftColumnSize)
        return <MobilePopup onClose={this.toggleSelectPanel}>
            <div className="select-popup-filter-title">Filter by {this.props.label}</div>
            <div className="select-popup-filter-details">
                <div className="select-popup-filter-details-column">
                    <div key="all" data-id="all" onClick={this.selectAll}
                        className={"select-checkbox" + allSelected}>All
                    </div>
                    {leftColumnData.map(o => this.renderCheckbox(o))}
                </div>
                <div className="select-popup-filter-details-column">
                    {rightColumnData.map(o => this.renderCheckbox(o))}
                </div>
            </div>
        </MobilePopup>
    }

    renderCheckbox(option) {
        let selected = this.props.selectedOptions.includes(option.id) ? " selected " : ""
        return <div key={option.id} data-id={option.id} onClick={this.selectOption}
                    className={"select-checkbox" + selected}>{option.pretty_name}</div>
    }

    render() {
        let allSelected = this.getSelectedString() === "All" ? " selected " : ""
        return (
            <div className={"filter filter-select" + (this.state.active ? " active" : "")}>
                <fieldset className="selectbox" onClick={this.toggleSelectPanel}><legend className='selectbox-legend'>{this.props.label}</legend>
                    <div className="selectbox-title">
                        {this.getSelectedString()}
                    </div>
                </fieldset>
                {this.state.isMobile ? this.renderMobileSelectPanel(allSelected) :
                    <fieldset className="checkboxes"
                              style={{display: (this.state.active ? "block" : "none"), marginTop: "-2px", overflow: 'auto'}}>
                        <div key="all" data-id="all" onClick={this.selectAll}
                             className={"select-checkbox" + allSelected}>All
                        </div>
                        {this.props.options.map((o) => this.renderCheckbox(o))}
                    </fieldset>
                }
            </div>

        )
    }
}

export default FilterSelect;