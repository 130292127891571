import React from 'react';
import { isMobile } from '../filters/filter_constants_and_helpers.jsx.erb';

class TrustPilot extends React.Component {
    constructor(props) {
        super(props);

        this.trustBoxRef = React.createRef();
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        document.body.appendChild(script);

        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }

    render() {
        return <div
            ref={this.trustBoxRef}
            className="trustpilot-widget"
            data-locale="en-US" data-template-id={isMobile() ? '5419b732fbfb950b10de65e5' : '5419b6ffb0d04a076446a9af'} data-businessunit-id="570d40500000ff00058b6dbe" data-style-height="24px" data-style-width="100%" data-theme={`${this.props.lightTheme ? "" : "dark"}`}>
            <a
                href="https://www.trustpilot.com/review/example.com"
                target="_blank"
                rel="noopener noreferrer"
            >Trustpilot</a>
        </div>
    }
}

export default TrustPilot;