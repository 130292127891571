import React from 'react';
import axios from 'axios';
import { H2, Subtitle, Regular, LargePrimaryButton, EmailInput } from '@worthy-npm/clarity';

class Blog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emailError: false,
            userEmailAddress: '',
            addressSent: false,
            showValidationMessage: false
        }

        this.sendEmailAddress = this.sendEmailAddress.bind(this)
    }

    async sendEmailAddress(stopAnimation) {
        this.setState({showValidationMessage: true})
        const {userEmailAddress} = this.state;
        if(this.state.isValidEmail){
            await axios.post(
                '/api/sellers/pre_lead/create_newsletter_subscribe',
                {email: userEmailAddress, origin: 'HP'}
                ).then((data) => {
                    stopAnimation()
                    this.setState({addressSent: true})
                    console.log(data)
                }, (error) => {
                    stopAnimation()
                    this.setState({
                        emailError: true,
                        addressSent: true
                    })
                    console.error(error)
                })
        } else {
            stopAnimation()
        }
    }

    updateLocalEmail(value, isValid){
        this.setState({
            userEmailAddress: value,
            isValidEmail: isValid
        })
    }

    renderForm() {
        return (
            <div className={'blog-form'}>
                <Subtitle text={'Sign up to get the latest in pre-owned jewelry'}/>
                <div className={'blog-email-container'}>
                    <EmailInput change={(value, isValid)=>this.updateLocalEmail(value, isValid)}
                                showValidationMessage={this.state.showValidationMessage}
                                label="Email Address"/>
                </div>
                <LargePrimaryButton click={(stopAnimation)=>this.sendEmailAddress(stopAnimation)} text={'GET OUR NEWSLETTER'}/>
            </div>
        )
    }

    renderReturnMessage() {
        if(!this.state.emailError){
            return (
                <div className={'blog-return-message'}>
                    <Subtitle text={'Subscription was successful!'}/>
                    <Regular text={'You’re in! Our next newsletter is coming soon...'}/>
                </div>
            )
        } else {
            return (
                <div className={'blog-return-message'}>
                    <Subtitle text={'An error has occurred. Please try again later.'}/>
                </div>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <H2 text={this.props.title}/>
                <div className={'blog-content'}>
                    <a href={this.props.url}>
                        <img src={this.props.src}/>
                    </a>
                    {!this.state.addressSent? this.renderForm() : this.renderReturnMessage()}
                </div>
            </React.Fragment>
        )
    }
}

export default Blog;